import { selectTypes } from "../../types/selects/types";

// ** Initial State
const initialState = {};

const select = (state = initialState, action) => {
    switch (action.type) {
        case selectTypes.addSelectOptions:
            return {
                ...state,
                [action.payload.key]: action.payload.options,
            };
        case selectTypes.addSelectValuedOptions:
            if (state[action.payload.key] === undefined) return { ...state, [action.payload.key]: [action.payload.option] };
            const isNewOption = state[action.payload.key]?.some((option) => option.value === action.payload.option.value);
            const newOptions = isNewOption ? [...state[action.payload.key], action.payload.option] : state[action.payload.key];
            return { ...state, [action.payload.key]: newOptions };
        case selectTypes.reset:
            return {
                ...initialState,
            };

        default:
            return state;
    }
};

export default select;
